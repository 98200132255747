import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const serverlessProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Streamline Development, Reduce Costs, and Scale Dynamically with{' '}
        <span className="heading-red">
          Our Serverless and Orchestrations Services
        </span>
      </>
    ),
    bannerContent:
      'We design scalable architectures, configure containers, and right-size resources to manage complex workflows and ensure optimal resource utilization.',
    BannerAlt: 'Serverless and Orchestrations Services',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Orchestrate and optimize resources for higher ROI with{' '}
        <span className="highlight-head">Simform</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We design optimized serverless architectures tailored to your
            infrastructure, balancing your current needs with future scalability
            and cost-efficiency.
            <br /> <br />
            Our AWS experts follow proven best practices to configure your
            serverless functions, databases, and API integrations for maximum
            business agility; they also utilize industry-leading orchestration
            with Kubernetes and Docker Swarm to automate container deployment,
            scaling, and management.
          </>
        ),
      },
      {
        detailsList: [
          'Centralized management of diverse environments.',
          'Automated provisioning and deployment of serverless functions',
          'Faster time-to-market and improved scalability',
          'Real-time monitoring and troubleshooting for business-critical apps',
          'Mitigated risk of data breaches and improved compliance',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our Serverless and Orchestration{' '}
        <span className="highlight-head">services</span>
      </>
    ),
    servicesPara:
      'Scale your applications dynamically while reducing infrastructure complexity. Choose from our range of serverless and orchestration services and shift your focus on delivering business value, not managing infrastructure.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Amazon EKS',
        className: 'custom-software',
        mainPara: (
          <>
            Simform uses its Amazon EKS expertise to provide fully managed
            Kubernetes container orchestration, simplifying cluster management.
            Our experts deploy and run containers so you can focus on core
            operations without the burden of handling underlying infrastructure.
          </>
        ),
        listItem: [
          {
            title: 'Custom cluster design and setup',
            para:
              'Our experts design and configure EKS clusters based on your application’s requirements, including instance types, storage, networking, IAM policies, and add-ons.  This helps you improve the management and orchestration of  Kubernetes clusters.',
          },
          {
            title: 'Kubernetes cost management',
            para:
              'Our experts manage ongoing EKS cluster operations right-size clusters, implement autoscaling, and leverage AWS spot instances to optimize and reduce Kubernetes infrastructure costs.',
          },
          {
            title: 'Application migration and containerization',
            para:
              'We can help you containerize your applications, create Docker images, and migrate your existing applications to the target EKS cluster with minimal downtime. This helps in reducing operational disruption and improves application performance.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-eks/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon ECS',
        className: 'software-testing',
        mainPara:
          'We offer tailored container orchestration services leveraging Amazon ECS and AWS Fargate for serverless containerization. Our team of AWS-certified experts ensures your container deployments are scalable, highly available, and secure.',
        listItem: [
          {
            title: 'Serverless-based deployments',
            para:
              'Our team helps you run APIs and applications serverlessly using Fargate integrated with Amazon ECS. This eliminates the need to manage the underlying infrastructure, reducing server management costs.',
          },
          {
            title: 'High security through concern isolation',
            para:
              'Our team of Amazon ECS experts enables rapid root cause analysis and isolation of concerns for enhanced system security. This reduces incident response time and ensures higher availability of the system.',
          },
          {
            title: 'Hassle-free and advanced integrations',
            para:
              'We simplify infrastructure management by leveraging Amazon ECS expertise to integrate innovative technologies like AI/ML without worrying about resource allocation. This helps you improve your application experience and offer smart features for your business.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/aws-ecs/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS Lambda',
        className: 'enterprise-mobile',
        mainPara:
          'Simform’s AWS Lambda expertise helps businesses like yours build serverless applications that scale automatically in response to changes in traffic. We handle provisioning and managing infrastructure and optimizing infrastructure costs.',
        listItem: [
          {
            title: 'Custom serverless ETL workflows',
            para:
              'Our team of AWS experts design and implement efficient customer ETL workflows to enable serverless capabilities. This ensures accelerated data processing, reduced time-to-insights, and cost-efficient scaling based on your workload.',
          },
          {
            title: 'Serverless architecture development',
            para:
              'We develop serverless architecture for your systems, taking into account design, configurations, workloads, and custom requirements. This allows you to improve the efficiency of your operations and leverage serverless capabilities.',
          },
          {
            title: 'Metadata management',
            para:
              'We help businesses organize, manage, and process metadata, leveraging serverless capabilities. This allows companies to extract vital information from metadata and enhance operations.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/aws-lambda-services',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon API Gateway',
        className: 'web-application',
        mainPara:
          'Simform simplifies creating, publishing, securing, and monitoring APIs of any size. We leverage API gateways to process thousands of concurrent API calls, including traffic management, authorization, and throttling.',
        listItem: [
          {
            title: 'Strategic API design',
            para:
              'We leverage API gateway expertise to strategically build, deploy, test, integrate, and customize APIs for your applications. This allows you to improve integrations and communication between services.',
          },
          {
            title: 'System performance optimization',
            para:
              'Our team of API Gateway experts enhances the system performance for speed, scalability, and cost-efficiency through fine-tuning configurations and enabling caching. This allows you to ensure optimal performance during peak loads.',
          },
          {
            title: 'API lifecycle management',
            para:
              "Our team helps you manage APIs as products, establishing versioning, environments, release processes, and deprecation policies to ensure your APIs' longevity, stability, uptime, and continuity.",
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-api-gateway/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS Graviton',
        className: 'dedicated-software',
        mainPara:
          'Our experts leverage AWS Graviton to improve your EC2 workload price performance by 40% compared to x86 instances. This helps us deliver significant cost savings and competitive performance for your business. ',
        listItem: [
          {
            title: 'Custom ARM-based solutions',
            para:
              'Our team of AWS experts design, develop, and implement custom ARM-based applications that leverage the capabilities of Graviton processors. This improves the price-to-performance ratio for your EC2 workloads.',
          },
          {
            title: 'Graviton processor integration',
            para:
              'Our AWS experts can seamlessly integrate Graviton processors into your workloads, leveraging its energy-efficient computing capabilities to maximize operational efficiency.',
          },
          {
            title: 'Migration planning and execution',
            para:
              'Our team of AWS experts creates a comprehensive migration strategy, assesses application compatibility, and guides a smooth transition to an ARM-based architecture while minimizing downtime.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-graviton/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Why choose <span className="highlight-head"> Simform?</span>
      </>
    ),
    processPara:
      'Simform’s serverless and container orchestration solutions follow a more holistic approach, optimizing costs and operational efficiency.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Modular architecture approach',
        workingBenefitsContent: (
          <>
            At Simform, we offer innovative modular architecture solutions that
            improve business agility.
            <br />
            <br />
            Our expert team customizes your application by integrating
            serverless architecture, reducing risks and increasing flexibility.
            This allows you to adapt quickly to the changing market demands.
          </>
        ),
        benefitImgAlt: 'Modular architecture approach',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Strategic serverless deployments',
        workingBenefitsContent: (
          <>
            Simform helps organizations streamline workloads through scalable
            serverless design for strategic application development.
            <br />
            <br />
            Our experts analyze your business requirements and plan custom
            serverless deployments to ensure optimal performance and seamless
            integration with your app development initiatives.
          </>
        ),
        benefitImgAlt: 'Strategic serverless deployments',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Secure data and compliance',
        workingBenefitsContent: (
          <>
            Simform enables security for your applications with
            containerization, which allows the isolation of concerns and risk
            identification.
            <br />
            <br />
            We ensure compliance with critical data regulations and enable a
            secure database infrastructure through containerization.
          </>
        ),
        benefitImgAlt: 'Secure data and compliance',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Consulting and technical support',
        workingBenefitsContent: (
          <>
            Simform offers end-to-end serverless and orchestration consulting
            and technical support through various stages of your application
            deployments.
            <br />
            <br />
            Whether app monitoring, measuring key metrics, or fine-tuning
            performance, our experts are with you every step, tailoring support
            for your team to utilize serverless architecture efficiently.
          </>
        ),
        benefitImgAlt: 'Consulting and technical support',
      },
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Work with an official{' '}
        <span className="highlight-head">AWS Premier Consulting Partner</span>
      </>
    ),
    para:
      'Simform, with its cloud partners, brings you the superpower of cloud-native applications. Our strategic alliances empower organizations like yours to thrive in the cloud.',
    gauranteeAlt: 'Extended tech team',
  },
  awardsRecognitionProps: {
    heading: 'Learn more about our AWS partner programs',
    AwsCardData: [
      {
        cardLink: '/aws-well-architected-review/',
      },
      {
        cardLink: '/aws-immersion-days/',
      },
    ],
  },
  relatedcaselistProps: {
    heading: (
      <>
        <span className="highlight-head">Case Studies</span>
      </>
    ),
    caselistProps: [
      {
        title: 'TagB',
        para: '360 marketing analytics platform for ecommerce leaders',
        className: 'mint',
        link: '/tagb-ecs/',
      },
      {
        title: 'Sweet Analytics',
        para: '360 marketing analytics platform for ecommerce leaders',
        className: 'light-peach',
        link: '/sweet-analytics/',
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: 'About Simform’s AWS Practices',
    detailsList: [
      <>
        <strong>
          Simform is an official premier consulting partner of AWS
        </strong>{' '}
        and hence, follows an approved set of practices that define the best
        work in configuring AWS services.
      </>,
      <>
        <strong>Devoted subject matter expert</strong> to walk you through your
        project and assist teams during the problems.
      </>,
      <>
        <strong>A well-structured team</strong> of technical officers whose
        significant tenure belongs to cloud technologies and AWS services.
      </>,
      <>
        <strong>A 24/7 constant communication</strong> to help you get through
        the AWS assistance, zero instances of delayed service.
      </>,
      <>
        <strong>Simform’s standard auditing and quality assurance</strong> over
        every module of code we deliver through our consulting services.
      </>,
    ],
  },
  featureResourceProps: {
    heading: 'AWS insights from our experts',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/serverless-architecture-guide/',
        featurecardTitle: 'Serverless Architecture: A Comprehensive Guide',
      },
      {
        blogLink:
          'https://www.simform.com/blog/lambda-provisioned-concurrency/',
        featurecardTitle:
          'AWS Lambda Provisioned Concurrency: Build High-performance Serverless Applications at Scale',
      },
      {
        blogLink: 'https://www.simform.com/blog/amazon-ecs-vs-eks/',
        featurecardTitle:
          'Amazon ECS vs. EKS: Which Container Service to Choose in 2023?',
      },
    ],
  },
}
